<template>
  <iq-main>
    <div class="container">
      <div class="wrapper">
        <!-- default -->
        <div class="holder" v-show="!showSuccess">
          <!-- icon -->
          <md-icon class="md-primary md-size-3x">password</md-icon>

          <!-- title & description -->
          <div class="md-title">Bitte wählen Sie ein neues Passwort</div>
          <div class="md-body-1">
            Das Passwort muss mindestens 8 Zeichen lang sein!
          </div>

          <md-field>
            <label>Neues Passwort</label>
            <md-input type="password" v-model.trim="new_password" />
          </md-field>

          <md-field>
            <label>Neues Passwort bestätigen</label>
            <md-input type="password" v-model.trim="confirm_new_password" />
          </md-field>

          <div>
            <md-button class="md-primary button-adjuster" @click="resetPassword"
              >Password zurücksetzen</md-button
            >
          </div>
        </div>

        <!-- success -->
        <div class="holder" v-show="showSuccess">
          <!-- icon -->
          <md-icon class="md-primary md-size-3x">task_alt</md-icon>

          <!-- title & description -->
          <div class="md-title">Das Passwort wurde zurückgesetzt!</div>
          <div class="md-body-1">
            Sie können Sich nun mit diesem Passwort anmelden.
          </div>

          <md-button class="md-primary" :to="{ name: 'Authorization' }"
            >Anmelden</md-button
          >
        </div>
      </div>
    </div>

    <!-- snackbar -->
    <md-snackbar :md-active.sync="showMsg">
      <span>{{ msg }}</span>
      <md-button class="md-accent" @click="disableSnackbarMessage"
        >close</md-button
      >
    </md-snackbar>
  </iq-main>
</template>

<script>
import axios from "axios";
import Settings from "@/config";

const AUTH_URI = Settings.API_URI_AUTH;

export default {
  name: "ResetPassword",
  data() {
    return {
      new_password: null,
      confirm_new_password: null,
      stt: null,
      showSuccess: false,
      msg: null,
      showMsg: false,
    };
  },
  created() {
    this.getSTT();
  },
  methods: {
    getSTT() {
      let stt = this.$route.query.stt;
      if (stt === undefined) {
        this.showSnackbarMessage();
      } else if (stt.length === 0) {
        this.showSnackbarMessage();
      } else {
        this.stt = stt;
      }
    },
    disableSnackbarMessage() {
      this.showMsg = this.showMsg !== true;
    },
    showSnackbarMessage() {
      this.msg = "Cannot reset password. Please try again later.";
      this.showMsg = true;
    },
    resetPassword() {
      let params = new URLSearchParams();
      params.set("stt", this.stt);

      axios
        .post(
          `${AUTH_URI}/password/reset`,
          {
            new_password: this.new_password,
            confirm_new_password: this.confirm_new_password,
          },
          {
            params: params,
          }
        )
        .then((response) => {
          // Reset input
          this.new_password = null;
          this.confirm_new_password = null;
          // Show message and success
          this.showSuccess = true;
          this.msg = response.data.msg;
          this.showMsg = true;
        })
        .catch((error) => {
          // Reset input
          this.new_password = null;
          // Show error
          this.confirm_new_password = null;
          if (error.response.status === 401)
            this.msg = error.response.data.detail;
          else this.msg = "Cannot reset password. Please try again later.";
          this.showMsg = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  & > .wrapper {
    position: relative;
    border-radius: 0.5rem;
    border: 1px solid var(--iq-gray-300);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    align-items: center;

    & > .holder {
      text-align: center;
    }
  }
}

.md-title {
  font-weight: bold;
}

.md-icon {
  margin: initial;
}

.w-30 {
  width: 30%;
}
</style>
